.ant-layout-sider-children {
  background: #006d86;
}
.ant-card-head-title {
  color: #006d86;
  margin: 10px;
  font-weight: 700;
  font-size: 18px;
}
#components-layout-demo-responsive .logo {
  height: 32px;
  background: #006d86;
  margin: 16px;
}
.site-layout-sub-header-background {
  background: #fff;
}
.site-layout-background {
  background: #fff;
}
.ant-menu-submenu-active {
  background: #fff;
  color: #006d86;
}
.ant-menu-inline {
  background: #006d86 !important;
  border-right: none !important;
}
.ant-menu-item-selected {
  background-color: #ffffff !important;
  color: #006d86 !important;
  border-right: 0;
}
.ant-menu-item-active {
  background-color: #ffffff;
  color: #006d86;
  border-right: 0;
}
.ant-menu-submenu-selected {
  color: #fff !important;
}
.ant-menu-horizontal > .ant-menu-submenu {
  border: none !important;
}
.customerLogoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 128px;
  background-color: white;
}
.customerLogo {
  padding-left: 10px;
  padding-right: 10px;
  max-height: 100%;
  max-width: 100%;
  display: block;
}
.adminMenuHolder {
  position: absolute;
  right: 1%;
  margin-left: 20px;
  margin-top: 7px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.ant-table table {
  text-align: center !important;
}
.ant-table-thead > tr > th {
  text-align: center !important;
}
.headerLogo {
  margin-left: 20px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.badge_high .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.badge_low .ant-badge-count {
  background-color: #52c41a;
  color: #fff;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.claircoLogoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 120px;
  background-color: white;
  padding: 3px;
}
.claircoLogo_admin {
  padding-left: 10px;
  padding-right: 10px;
  max-height: 100%;
  max-width: 100%;
  display: block;
}
.ant-menu-horizontal {
  border: none !important;
}
.important-mark::after {
  content: "*";
  color: red;
  font-size: 14px;
  padding-right: 4px;
}
.ant-menu-item-active {
  background: white !important;
  color: #00695c !important;
}
