#root {
  font-family: "Open Sans", sans-serif !important;
}
body {
  font-family: "Open Sans", sans-serif !important;
}
.logoHome {
  max-height: 3 rem;
  float: left;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.dashboardHeaderContent {
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 24px;
}
.contentConatiner {
  min-height: 700px;
  padding-top: 100px;
  max-width: 1152px;
  margin-top: 1000px;
}
.homeContentContainer {
  min-height: 700px;
  padding-top: 80px;
  max-width: 1152px;
  margin: auto;
}
.homeLayout {
  background: white;
  font-weight: 400 !important;
  letter-spacing: -0.3px;
}
.homeHeader {
  background: white;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px;
  position: fixed;
  z-index: 1;
  width: 100%;
}
.homeHeaderContainer {
  min-height: 80px;
  align-items: center;
  display: flex;
  padding: 16px;
  position: fixed;
  width: 100%;
  margin: auto;
  max-width: 1152px;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.dashLogo {
  min-height: 60px;
  width: 100%;
  background: black;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
.headerLogo {
  margin-left: 20px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.dashDownload {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 8px;
  margin-top: 20px;
  color: #00695c;
  background-color: #ffffff;
}
.dashDownload1 {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 16px;
  margin-top: 100px;
}
.logOut {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  align-items: center;
  border-radius: 25px;
  margin: 10px auto 10px 10px;
  display: flex;
  color: #006d86;
  background-color: #ffffff;
}
.dashHeader {
  background: white;
  padding: 0;
  box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.1);
}
.userMenuHolder {
  position: absolute;
  right: 20%;
  margin-left: 20px;
  margin-top: 7px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.summaryDate {
  position: absolute;
  right: 8%;
  margin-top: -20px;
}
.sider {
  overflow-y: scroll;
  overflow-x: hidden;
  background: #006d86;
  box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.1);
}
.dashLoaderContainer {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.claircoLogo {
  display: block;
  max-width: 83px;
  max-height: 100px;
}
.customerLogoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 128px;
  background-color: white;
}
.customerLogo {
  max-height: 100%;
  max-width: 100%;
  display: block;
}
.buttonCityNamesList {
  margin: 30px auto 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationIcon {
  color: #6aa25c;
}
.innerMenuItems {
  background: #006d86;
  color: #fff;
}
.ant-menu-submenu-active {
  background: #fff;
  color: #006d86;
}
.ant-menu-inline {
  background: #006d86 !important;
  color: #fff !important;
  border-right: none !important;
}
.rowAvatarCompany {
  align-items: "center";
}
.avatarCompnay {
  border-bottom: 50%;
}
.progressLabels {
  text-align: center;
  margin: -125px 0px 0px -49px;
}
.progressLabels_Value {
  font-size: 27px;
}
.progressLabels_units {
  font-size: 15px;
}
.progressLabels_label {
  font-size: 27px;
}
.ant-menu-item-selected {
  background-color: #ffffff !important;
}
.ant-menu-item-active {
  background-color: #ffffff;
}
.ant-menu-submenu-selected {
  color: #fff;
}
.endDatePicker {
  margin-left: 10px;
}
.notificationShade {
  width: 200px;
  background: #fff;
  padding: 35px;
}
.body-card-header-pop {
  width: max-content;
  background-color: #006d86;
  border-radius: 15px;
  margin-top: -30px;
  box-shadow: 2px 2px #d2d2d2;
  text-align: center;
  color: #fff;
  font-size: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.body-card-header {
  border-radius: 30px;
  background: #fff;
  box-shadow: 2px 2px #d2d2d2;
}
.body-card-container {
  border-radius: 10px;
  background: #fff !important;
  margin: 5px !important;
  box-shadow: 2px 2px #d2d2d2;
}
.add_button {
  margin: 15px;
  border-radius: 50%;
  float: right;
}
.purifier_report_button {
  background-color: #006d86;
  color: #fff;
}
.small_card_icon {
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding: 3px;
  width: 30px;
  border-radius: 7%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}
.small_margin {
  margin: 4px;
}
.margin-top-15p {
  margin-top: 15px;
}
.kioskIcon {
  position: absolute;
  right: 5%;
  border-radius: 5px;
  background-color: #009688;
}
.chatViewHeader {
  background-color: #ffffff;
  margin-bottom: 7px;
  height: 60px;
  line-height: 10px;
  padding: 15px;
  font-size: 16px;
}
.chatViewFooter {
  background-color: #ffffff;
  margin-bottom: 7px;
  height: 60px;
  line-height: 10px;
  padding: 15px;
  font-size: 16px;
}
.hsmButtonContainer {
  position: absolute;
  right: 5%;
  /* margin-left: 20px; */
  /* margin-top: 7px; */
  display: -ms-inline-flexbox;
  display: inline-flex;
  max-width: 300px;
  -ms-flex-align: center;
  align-items: center;
}
.conversationContainer {
  background-color: #d2d2d2;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.message_card {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.float_right {
  float: right;
}
.float_left {
  float: left;
}
