.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-menu-item-selected > .anticon {
  color: #00695c !important;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #00695c;
}
.ant-menu:not(.ant-menu-horizontal) {
  background-color: #00695c !important;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
.homeHeaderContainer {
  min-height: 80px;
  align-items: center;
  display: flex;
  padding: 10px;
  position: fixed;
  width: 100%;
  margin: auto;
  max-width: 1152px;
}
.logoHome {
  max-height: 5rem !important;
  float: left;
  margin-top: -16%;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.sidermenu {
  margin-top: 300px !important;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #388dec;
}
.site-layout .site-layout-background {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  padding: 10px;
}
.ant-layout-sider-children {
  box-shadow: 2px 0px 10px -3px #000;
  background-color: #00796b;
}
.ant-menu-root {
  background-color: #009688;
}
.header_menu_holder {
  position: absolute;
  right: 5%;
  display: -ms-inline-flexbox;
  display: inline-flex;
  max-width: 180px;
  -ms-flex-align: center;
  align-items: center;
}
.homeContentContainer {
  max-width: 1690px;
}
.user_aqi_info_card {
  padding: 15px;
  border: none;
  box-shadow: 10px 10px 20px -25px #000000;
  height: 290px;
  border-radius: 6px;
}
.summary_aqi_holder {
  margin-left: 35px;
  width: 100%;
}
.aqi_holder_card {
  cursor: pointer;
  margin: 10px;
  padding: 8px;
  color: #fff;
  border-radius: 6px;
}
.excellent {
  background: linear-gradient(to right, #006400, #0f9b0f);
}
.good {
  background-image: linear-gradient(to right top, #00695c, #00796b, #00897b, #009688, #26a69a);
}
.moderate {
  background-image: linear-gradient(to right top, #fc4a1a, #f7b733);
}
.bad {
  background-image: linear-gradient(to right top, #ed213a, #93291e);
}
.aqi_holder_card_outside {
  cursor: pointer;
  margin: 10px;
  padding: 8px;
  color: #fff;
  border-radius: 6px;
  background-image: linear-gradient(to right top, #db2e2e, #e86111, #e98d00, #e1b800, #cee011);
}
.aqi_holder_card_name {
  font-size: 18px;
  font-weight: 500;
}
.aqi_holer_card_value {
  font-size: 26px;
  font-weight: 700;
}
.aqi_holder_card_unit {
  font-size: 17px;
  font-weight: 400;
}
.user_list_holder {
  box-shadow: 10px 10px 20px -25px #000000;
  margin-left: 45px;
  width: 79.5%;
}
.user_gauge_card_carousel {
  margin-top: 30px !important;
  padding: 15px;
  border: none;
  box-shadow: 10px 10px 20px -25px #000000;
  height: 290px;
  border-radius: 6px;
  text-align: -webkit-center;
}
.aqi_info_card_title {
  font-size: 50px;
  font-weight: 500;
}
.aqi_info_card_subtitle {
  font-size: 18px;
  font-weight: 200;
}
.aqi_info_card_image {
  position: fixed;
  width: 300px;
  height: 220px;
  float: right;
}
.temp_data {
  font-size: 13px;
}
.circle-background,
.circle-progress {
  fill: none;
}
.circle-background {
  stroke: #828080;
}
.circle-dashes {
  stroke: #fff;
  fill: none;
}
.gauge_card_icon_holder {
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: #00695c;
  border-radius: 5px;
  box-shadow: 10px 10px 20px -25px #000000;
  text-align: center;
}
.gauge_card_desc {
  position: absolute;
  top: 0;
  left: 25px;
  text-align: left;
  padding: 10px;
  font-size: 15px;
}
.aqi_indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-flex;
  margin-left: 10px;
  margin-right: 10px;
}
.green {
  background-color: #26a69a;
}
.red {
  background-color: #ef5350;
}
.aqi_card_header_title {
  float: left;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4%;
  padding: 0px 10px 0px 10px;
}
.indoor {
  color: #006d86;
}
.outdoor {
  color: #ef5350;
}
.center {
  margin: auto;
  position: relative;
  top: 35%;
}
.gauge_outer_ring {
  position: absolute;
}
.gauge_inside_progress {
  position: absolute;
  width: 200px;
}
.CircularProgressbar .CircularProgressbar-text {
  font-size: 25px !important;
  fill: rgba(0, 0, 0, 0.65) !important;
  color: #26a69a !important;
}
.excellent_aqi > .CircularProgressbar-path {
  stroke: #00940f !important;
}
.good_aqi > .CircularProgressbar-path {
  stroke: #009966 !important;
}
.moderate_aqi > .CircularProgressbar-path {
  stroke: #ffde33 !important;
}
.bad_aqi > .CircularProgressbar-path {
  stroke: #ff9933 !important;
}
.sever_aqi > .CircularProgressbar-path {
  stroke: #cc0033 !important;
}
.hazard_aqi > .CircularProgressbar-path {
  stroke: #7e0023 !important;
}
.gauge_card_icon_label {
  position: absolute;
  top: 0;
  right: 25px;
  text-align: right;
  padding: 10px;
  font-size: 20px;
}
.gauge_card_icon_info {
  position: absolute;
  bottom: 0;
  left: 25px;
  text-align: left;
  padding: 10px;
  font-size: 13px;
}
.gauge_info_icon {
  cursor: pointer;
  color: red;
}
.gauge_card_bg {
  width: 100%;
  opacity: 0.1;
  height: 95%;
}
.image_container {
  width: 98.2%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}
.chartjs-render-monitor {
  height: 209px !important;
  width: 460px !important;
}
.aqi_card_header {
  margin-right: 10px;
  cursor: pointer;
}
.ant-card-body {
  padding: 9px !important;
}
.ant-card-head {
  border-bottom: none !important;
  color: #fff;
  text-align: right;
}
.gauge_container {
  display: none;
}
.active {
  display: block !important;
}
.ant-menu-inline {
  background-color: #23538a !important;
}
#tsparticles {
  position: absolute;
  margin-top: -46px;
  margin-left: -18px;
  height: 100%;
}
.download_button {
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: #23538a;
  color: rgba(255, 255, 255, 0.65);
  padding-left: 20px;
}
.add_user {
  background-color: #388dec;
  color: #fff;
  font-weight: 500;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}
#customers tr:hover {
  background-color: #ddd;
}
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
.customer_name_container {
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-top: 30px;
  border-radius: 20px;
  font-size: 14px;
  color: #00695c !important;
  margin-left: 15px;
  width: 170px;
}
.col_header_custom {
  background: #dedede;
  color: #00796b;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bolder;
  padding: 10px;
  margin: 5px 0 5px 0;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip .tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.ant-menu-item-active {
  background: white !important;
  color: #00695c !important;
}
