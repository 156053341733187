.colorInfoContainer {
  margin-top: 16px;
  font-size: 12px;
}
.colorInfoBar {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.boxContainer {
  text-align: center;
  max-width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colorInfoGood {
  background: #00940F;
}
.colorInfoSatisfactory {
  background: #62C94B;
}
.colorInfoModerate {
  background: #F7E22A;
}
.colorInfoPoor {
  background: #FF6600;
}
.colorInfoVeryPoor {
  background: #FF0000;
}
.colorInfoSevere {
  background: #870003;
}
.colorInfoInside {
  background: #006d86;
}
.colorInfoOutside {
  background: #00e5ff;
}
