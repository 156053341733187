.summaryContainer {
  min-height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summaryLoaderContainer {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.summaryHeaderContainer {
  background-color: #ffffff;
  margin-top: -12px;
  margin-bottom: 7px;
  height: 40px;
  line-height: 0px;
  padding: 0 15px;
  font-size: 16px;
}
.summaryHeader {
  height: 100%;
  line-height: 0px !important;
  margin-left: 0px;
  display: inline-flex;
  max-width: 600px;
  align-items: center;
}
.rtAqOutside {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rtAqOutsideContainer {
  display: flex;
  margin: 10px;
  border-radius: 20px;
  width: 80px;
  height: 40px;
  align-items: center;
  color: #FFF;
  font-weight: bolder;
  font-size: large;
  justify-content: center;
}
.extraCardHeader {
  font-size: 10px;
}
.propsCarouselCardClass {
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
}
.header_block_card {
  width: 25%;
  margin-top: 22px;
  margin-bottom: 20px;
}
.fail {
  background: linear-gradient(60deg, #ef5350, #e53935);
}
.success {
  background: linear-gradient(60deg, #66bb6a, #43a047);
}
.disconnection {
  background-image: linear-gradient(to right bottom, #f49b29, #f5b025, #f3c425, #efd92d, #e7ee3c);
}
.block_card_icon {
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 50px;
  padding: 10px;
  margin-top: -32px;
  border-radius: 3%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}
.block_card_info_header {
  font-size: small;
  text-align: end;
  font-family: OpenSans sans-serif;
  font-weight: bold;
  color: #999;
  margin-top: -10%;
}
.card_info {
  font-family: OpenSans sans-serif;
  font-size: 18px;
  text-align: end;
}
.info_units {
  text-align: end;
  font-size: 10px;
  font-weight: bold;
}
.custom_divider {
  margin: 0px 0px 10px 0px !important;
}
