.colorInfoContainerOps {
  margin-top: 16px;
  font-size: 6px;
}
.colorInfoBarOps {
  display: inline-block;
  height: 4px;
  width: 4px;
  margin-right: 2px;
  border-radius: 50%;
}
.boxContainerOps {
  text-align: center;
  max-width: 52px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colorInfoGood {
  background: #00940F;
}
.colorInfoSatisfactory {
  background: #62C94B;
}
.colorInfoModerate {
  background: #F7E22A;
}
.colorInfoPoor {
  background: #FF6600;
}
.colorInfoVeryPoor {
  background: #FF0000;
}
.colorInfoSevere {
  background: #870003;
}
.colorInfoInside {
  background: #006d86;
}
.colorInfoOutside {
  background: #00e5ff;
}
