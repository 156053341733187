@media screen and (min-width: 930px) and (max-width: 2000px) {
  .header_row {
    display: block;
  }
  .AqiLabel {
    font-size: 40px;
  }
  .ant-col-20 {
    width: 55.3%;
  }
  .inside_Card_holder {
    width: 50%;
  }
  .outside_Card_holder {
    width: 50%;
  }
  .slide_image {
    width: 40% !important;
    margin-top: 2%;
  }
  .banner_row {
    margin-bottom: 10px;
  }
  .kiosk_container {
    margin-top: 0px;
    overflow: hidden;
  }
  .footerHeader {
    margin-top: 20px;
  }
  .inside_banner {
    width: 60.2% !important;
  }
  .outside_banner {
    width: 57.2% !important;
  }
}
@media screen and (min-width: 930px) and (max-width: 1080px) {
  .header_row {
    display: none;
  }
  .AqiLabel {
    font-size: 42px !important;
  }
  .banner_row {
    margin-bottom: 5px;
  }
  .aqi_card_holders {
    margin-top: 0px;
  }
  .kiosk_container {
    margin-top: 0px;
  }
  .outside_banner {
    width: 48.2% !important;
  }
  .inside_banner {
    width: 50.2% !important;
  }
  .footerHeader {
    margin-top: 10px;
  }
  .footer_items {
    margin-top: -10px;
  }
}
@media screen and (max-width: 1100px) {
  .header_row {
    display: block;
  }
  .AqiLabel {
    font-size: 59px;
  }
  .inside_Card_holder {
    width: 100%;
  }
  .outside_Card_holder {
    width: 100%;
  }
  .slide_image {
    width: 100% !important;
    margin-top: 15%;
  }
  .banner_row {
    margin-bottom: 30px;
  }
  .kiosk_container {
    margin-top: 0px;
  }
  .footerHeader {
    margin-top: 20px;
  }
  .inside_banner {
    width: 60.2% !important;
  }
  .outside_banner {
    width: 57.2% !important;
  }
  .footer_items {
    margin-top: 10px;
  }
}
.kiosk_container {
  font-family: "montessrat";
  text-align: center;
  overflow-x: hidden;
  width: 100%;
  margin: 0 auto;
}
.AqiLabel {
  font-size: 45px;
}
.SpaceNameLabel {
  height: 70px;
  font-size: 33px;
}
.valuesContainer {
  align-items: center;
  margin: 0 10px 0 10px !important;
}
.aqLabelHolder {
  margin: auto;
  box-align: center;
  font-size: 27px;
}
.aqValueHolder {
  font-size: 53px;
  border-radius: 25px;
  width: 200px;
  height: 70px;
  color: #000;
}
.aqValueUnits {
  font-size: 10px;
}
.noticeDialogCard {
  border-radius: 10px;
  background-color: #fbfbcf;
  color: #d30000;
  font-size: large;
}
.ftlvlItems {
  margin: 10px;
  border-radius: 20px;
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ftlvlItemsContainer {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bolder;
  font-size: 20px;
}
.footerLogo {
  position: absolute;
  left: 0;
  display: block;
  max-width: 229px;
  max-height: 100px;
  bottom: 0;
}
.shape {
  width: 20px;
  height: 40px;
  border-radius: 20px;
  margin: 8px;
  margin-top: -5px;
}
.side_shape {
  position: relative;
  width: 17px;
  height: 36px;
  border-radius: 13px;
}
.slide {
  background: #fff !important;
}
.control-arrow {
  color: #000 !important;
}
.kiosk_footer_banner {
  width: 284px !important;
}
.headerlabel {
  display: inline-flex;
  margin-top: 2%;
  font-size: 30px;
  padding-left: 15px !important;
}
.customer {
  float: left;
  width: 176px !important;
  margin-left: 30px;
}
.clairco {
  float: right;
  width: 100px !important;
  margin: 20px;
}
.bottom_logo {
  width: 145px;
}
.image_upload {
  display: inline-block !important;
  margin: 10px;
  background: #fff;
}
.kiosk_image_card_container {
  display: inline-block;
  width: 260px;
  height: 200px;
  margin-top: 20px;
}
.kiosk_image_card {
  width: 200px;
  height: 190px;
}
.image_list_container {
  overflow: scroll;
  width: 100%;
  height: 400px;
  background: #fff;
  padding: 20px;
}
.footerHeader {
  background-color: #464646;
  color: #fff;
  font-size: xx-large;
  height: 70px;
}
.footerContainer {
  background-color: #f2f2f2;
  height: 105px;
  padding: 0;
}
.footer_items {
  margin-top: 0;
}
@media screen and (min-width: 650px) {
  .thumbs-wrapper {
    margin: 0;
    overflow: unset !important;
  }
  .aqi_card_holders {
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 283px);
  }
  .banner_row {
    margin: 10px 0 0 0;
  }
  .AqiLabel {
    font-size: 32px;
  }
  .aqValueHolder {
    font-size: 36px;
    width: 147px;
    height: 50px;
  }
  .footerHeader {
    height: 70px;
  }
  .footerContainer {
    height: 105px;
  }
}
