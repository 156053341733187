@font-face {
  font-family: "KarlaReg";
  src: local("KarlaReg"), url(/static/media/Karla-Regular.fd3f938a.ttf) format("truetype");
}




@media screen and (min-width: 930px) and (max-width: 2000px) {
  .header_row {
    display: block;
  }
  .AqiLabel {
    font-size: 40px;
  }
  .ant-col-20 {
    width: 55.3%;
  }
  .inside_Card_holder {
    width: 50%;
  }
  .outside_Card_holder {
    width: 50%;
  }
  .slide_image {
    width: 40% !important;
    margin-top: 2%;
  }
  .banner_row {
    margin-bottom: 10px;
  }
  .kiosk_container {
    margin-top: 0px;
    overflow: hidden;
  }
  .footerHeader {
    margin-top: 20px;
  }
  .inside_banner {
    width: 60.2% !important;
  }
  .outside_banner {
    width: 57.2% !important;
  }
}
@media screen and (min-width: 930px) and (max-width: 1080px) {
  .header_row {
    display: none;
  }
  .AqiLabel {
    font-size: 42px !important;
  }
  .banner_row {
    margin-bottom: 5px;
  }
  .aqi_card_holders {
    margin-top: 0px;
  }
  .kiosk_container {
    margin-top: 0px;
  }
  .outside_banner {
    width: 48.2% !important;
  }
  .inside_banner {
    width: 50.2% !important;
  }
  .footerHeader {
    margin-top: 10px;
  }
  .footer_items {
    margin-top: -10px;
  }
}
@media screen and (max-width: 1100px) {
  .header_row {
    display: block;
  }
  .AqiLabel {
    font-size: 59px;
  }
  .inside_Card_holder {
    width: 100%;
  }
  .outside_Card_holder {
    width: 100%;
  }
  .slide_image {
    width: 100% !important;
    margin-top: 15%;
  }
  .banner_row {
    margin-bottom: 30px;
  }
  .kiosk_container {
    margin-top: 0px;
  }
  .footerHeader {
    margin-top: 20px;
  }
  .inside_banner {
    width: 60.2% !important;
  }
  .outside_banner {
    width: 57.2% !important;
  }
  .footer_items {
    margin-top: 10px;
  }
}
.kiosk_container {
  font-family: "montessrat";
  text-align: center;
  overflow-x: hidden;
  width: 100%;
  margin: 0 auto;
}
.AqiLabel {
  font-size: 45px;
}
.SpaceNameLabel {
  height: 70px;
  font-size: 33px;
}
.valuesContainer {
  align-items: center;
  margin: 0 10px 0 10px !important;
}
.aqLabelHolder {
  margin: auto;
  box-align: center;
  font-size: 27px;
}
.aqValueHolder {
  font-size: 53px;
  border-radius: 25px;
  width: 200px;
  height: 70px;
  color: #000;
}
.aqValueUnits {
  font-size: 10px;
}
.noticeDialogCard {
  border-radius: 10px;
  background-color: #fbfbcf;
  color: #d30000;
  font-size: large;
}
.ftlvlItems {
  margin: 10px;
  border-radius: 20px;
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ftlvlItemsContainer {
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: bolder;
  font-size: 20px;
}
.footerLogo {
  position: absolute;
  left: 0;
  display: block;
  max-width: 229px;
  max-height: 100px;
  bottom: 0;
}
.shape {
  width: 20px;
  height: 40px;
  border-radius: 20px;
  margin: 8px;
  margin-top: -5px;
}
.side_shape {
  position: relative;
  width: 17px;
  height: 36px;
  border-radius: 13px;
}
.slide {
  background: #fff !important;
}
.control-arrow {
  color: #000 !important;
}
.kiosk_footer_banner {
  width: 284px !important;
}
.headerlabel {
  display: inline-flex;
  margin-top: 2%;
  font-size: 30px;
  padding-left: 15px !important;
}
.customer {
  float: left;
  width: 176px !important;
  margin-left: 30px;
}
.clairco {
  float: right;
  width: 100px !important;
  margin: 20px;
}
.bottom_logo {
  width: 145px;
}
.image_upload {
  display: inline-block !important;
  margin: 10px;
  background: #fff;
}
.kiosk_image_card_container {
  display: inline-block;
  width: 260px;
  height: 200px;
  margin-top: 20px;
}
.kiosk_image_card {
  width: 200px;
  height: 190px;
}
.image_list_container {
  overflow: scroll;
  width: 100%;
  height: 400px;
  background: #fff;
  padding: 20px;
}
.footerHeader {
  background-color: #464646;
  color: #fff;
  font-size: xx-large;
  height: 70px;
}
.footerContainer {
  background-color: #f2f2f2;
  height: 105px;
  padding: 0;
}
.footer_items {
  margin-top: 0;
}
@media screen and (min-width: 650px) {
  .thumbs-wrapper {
    margin: 0;
    overflow: unset !important;
  }
  .aqi_card_holders {
    width: 100%;
    margin: 0 auto;
    height: calc(100vh - 283px);
  }
  .banner_row {
    margin: 10px 0 0 0;
  }
  .AqiLabel {
    font-size: 32px;
  }
  .aqValueHolder {
    font-size: 36px;
    width: 147px;
    height: 50px;
  }
  .footerHeader {
    height: 70px;
  }
  .footerContainer {
    height: 105px;
  }
}

#root {
  font-family: "Open Sans", sans-serif !important;
}
body {
  font-family: "Open Sans", sans-serif !important;
}
.logoHome {
  max-height: 3 rem;
  float: left;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.dashboardHeaderContent {
  align-items: center;
  justify-content: center;
  display: flex;
  font-family: "Karla", sans-serif;
  font-weight: bold;
  color: #000000;
  font-size: 24px;
}
.contentConatiner {
  min-height: 700px;
  padding-top: 100px;
  max-width: 1152px;
  margin-top: 1000px;
}
.homeContentContainer {
  min-height: 700px;
  padding-top: 80px;
  max-width: 1152px;
  margin: auto;
}
.homeLayout {
  background: white;
  font-weight: 400 !important;
  letter-spacing: -0.3px;
}
.homeHeader {
  background: white;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px;
  position: fixed;
  z-index: 1;
  width: 100%;
}
.homeHeaderContainer {
  min-height: 80px;
  align-items: center;
  display: flex;
  padding: 16px;
  position: fixed;
  width: 100%;
  margin: auto;
  max-width: 1152px;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}
.dashLogo {
  min-height: 60px;
  width: 100%;
  background: black;
  display: flex;
  align-items: center;
  padding-left: 32px;
}
.headerLogo {
  margin-left: 20px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.dashDownload {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 8px;
  margin-top: 20px;
  color: #00695c;
  background-color: #ffffff;
}
.dashDownload1 {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 16px;
  margin-top: 100px;
}
.logOut {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  align-items: center;
  border-radius: 25px;
  margin: 10px auto 10px 10px;
  display: flex;
  color: #006d86;
  background-color: #ffffff;
}
.dashHeader {
  background: white;
  padding: 0;
  box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.1);
}
.userMenuHolder {
  position: absolute;
  right: 20%;
  margin-left: 20px;
  margin-top: 7px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.summaryDate {
  position: absolute;
  right: 8%;
  margin-top: -20px;
}
.sider {
  overflow-y: scroll;
  overflow-x: hidden;
  background: #006d86;
  box-shadow: 0 0.2px 2px rgba(0, 0, 0, 0.1);
}
.dashLoaderContainer {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.claircoLogo {
  display: block;
  max-width: 83px;
  max-height: 100px;
}
.customerLogoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 128px;
  background-color: white;
}
.customerLogo {
  max-height: 100%;
  max-width: 100%;
  display: block;
}
.buttonCityNamesList {
  margin: 30px auto 30px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.locationIcon {
  color: #6aa25c;
}
.innerMenuItems {
  background: #006d86;
  color: #fff;
}
.ant-menu-submenu-active {
  background: #fff;
  color: #006d86;
}
.ant-menu-inline {
  background: #006d86 !important;
  color: #fff !important;
  border-right: none !important;
}
.rowAvatarCompany {
  align-items: "center";
}
.avatarCompnay {
  border-bottom: 50%;
}
.progressLabels {
  text-align: center;
  margin: -125px 0px 0px -49px;
}
.progressLabels_Value {
  font-size: 27px;
}
.progressLabels_units {
  font-size: 15px;
}
.progressLabels_label {
  font-size: 27px;
}
.ant-menu-item-selected {
  background-color: #ffffff !important;
}
.ant-menu-item-active {
  background-color: #ffffff;
}
.ant-menu-submenu-selected {
  color: #fff;
}
.endDatePicker {
  margin-left: 10px;
}
.notificationShade {
  width: 200px;
  background: #fff;
  padding: 35px;
}
.body-card-header-pop {
  width: -webkit-max-content;
  width: max-content;
  background-color: #006d86;
  border-radius: 15px;
  margin-top: -30px;
  box-shadow: 2px 2px #d2d2d2;
  text-align: center;
  color: #fff;
  font-size: 30px;
  padding-left: 30px;
  padding-right: 30px;
}
.body-card-header {
  border-radius: 30px;
  background: #fff;
  box-shadow: 2px 2px #d2d2d2;
}
.body-card-container {
  border-radius: 10px;
  background: #fff !important;
  margin: 5px !important;
  box-shadow: 2px 2px #d2d2d2;
}
.add_button {
  margin: 15px;
  border-radius: 50%;
  float: right;
}
.purifier_report_button {
  background-color: #006d86;
  color: #fff;
}
.small_card_icon {
  color: #fff;
  text-align: center;
  font-size: 20px;
  padding: 3px;
  width: 30px;
  border-radius: 7%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}
.small_margin {
  margin: 4px;
}
.margin-top-15p {
  margin-top: 15px;
}
.kioskIcon {
  position: absolute;
  right: 5%;
  border-radius: 5px;
  background-color: #009688;
}
.chatViewHeader {
  background-color: #ffffff;
  margin-bottom: 7px;
  height: 60px;
  line-height: 10px;
  padding: 15px;
  font-size: 16px;
}
.chatViewFooter {
  background-color: #ffffff;
  margin-bottom: 7px;
  height: 60px;
  line-height: 10px;
  padding: 15px;
  font-size: 16px;
}
.hsmButtonContainer {
  position: absolute;
  right: 5%;
  /* margin-left: 20px; */
  /* margin-top: 7px; */
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.conversationContainer {
  background-color: #d2d2d2;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.message_card {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.float_right {
  float: right;
}
.float_left {
  float: left;
}

.summaryContainer {
  min-height: 600px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.summaryLoaderContainer {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.summaryHeaderContainer {
  background-color: #ffffff;
  margin-top: -12px;
  margin-bottom: 7px;
  height: 40px;
  line-height: 0px;
  padding: 0 15px;
  font-size: 16px;
}
.summaryHeader {
  height: 100%;
  line-height: 0px !important;
  margin-left: 0px;
  display: inline-flex;
  max-width: 600px;
  align-items: center;
}
.rtAqOutside {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rtAqOutsideContainer {
  display: flex;
  margin: 10px;
  border-radius: 20px;
  width: 80px;
  height: 40px;
  align-items: center;
  color: #FFF;
  font-weight: bolder;
  font-size: large;
  justify-content: center;
}
.extraCardHeader {
  font-size: 10px;
}
.propsCarouselCardClass {
  padding-left: 50px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 10px;
}
.header_block_card {
  width: 25%;
  margin-top: 22px;
  margin-bottom: 20px;
}
.fail {
  background: linear-gradient(60deg, #ef5350, #e53935);
}
.success {
  background: linear-gradient(60deg, #66bb6a, #43a047);
}
.disconnection {
  background-image: linear-gradient(to right bottom, #f49b29, #f5b025, #f3c425, #efd92d, #e7ee3c);
}
.block_card_icon {
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-size: 50px;
  padding: 10px;
  margin-top: -32px;
  border-radius: 3%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}
.block_card_info_header {
  font-size: small;
  text-align: end;
  font-family: OpenSans sans-serif;
  font-weight: bold;
  color: #999;
  margin-top: -10%;
}
.card_info {
  font-family: OpenSans sans-serif;
  font-size: 18px;
  text-align: end;
}
.info_units {
  text-align: end;
  font-size: 10px;
  font-weight: bold;
}
.custom_divider {
  margin: 0px 0px 10px 0px !important;
}

.colorInfoContainer {
  margin-top: 16px;
  font-size: 12px;
}
.colorInfoBar {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.colorInfoCircle {
  display: inline-block;
  height: 8px;
  width: 8px;
  margin-right: 3px;
  border-radius: 50%;
}
.boxContainer {
  text-align: center;
  max-width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colorInfoExcellentCapitaLand {
  background: #00940F;
}
.colorInfoGoodCapitaLand {
  background: #62C94B;
}
.colorInfoPoorCapitaLand {
  background: #FF0000;
}
.colorInfoInside {
  background: #006d86;
}
.colorInfoOutside {
  background: #00e5ff;
}

.dashboard-content-chart {
  height: 100;
}
.pmCard {
  box-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.1);
  height: 274px;
}
.noDataPmChart {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  margin: "auto";
  display: flex;
}
.rtAqOutside {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rtAqOutsideContainer {
  display: flex;
  margin: 10px;
  border-radius: 20px;
  width: 80px;
  height: 40px;
  align-items: center;
  color: #FFF;
  font-weight: bolder;
  font-size: large;
  justify-content: center;
}
.rtAqOutsideContainer2 {
  background-color: aqua;
  display: flex;
  margin: 10px;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  color: #000;
  font-weight: bolder;
  font-size: large;
  justify-content: center;
}
.separator {
  margin: 0 !important;
}
.aqiGraphSarva > .ant-card-body {
  padding: 0px;
}
.propertiesLoaderContainer {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.logoHome {
  max-height: 5rem;
  float: left;
}
.logoHomeFooter {
  max-height: 6rem;
  float: left;
}
.homeProfileNameText {
  padding: 5px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  font-size: 25px;
  color: #0a0a0a;
}
.homeProfileContentText {
  padding: 2px;
  padding-top: 0px;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  justify-content: center;
  font-size: 20px;
  color: #0a0a0a;
  max-height: 40px;
  min-height: 40px;
}
.logoProfile {
  max-height: 12rem;
  border-radius: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.logoLinkedin {
  max-height: 2.5rem;
  border-radius: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.homeProfileTextContainer {
  min-height: 580px;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homeProfileLogo {
  font-size: 40px;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  color: #0E76A8;
}
.homeLogo {
  font-size: 40px;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}
.homeHeader {
  background: white;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px;
  position: fixed;
  z-index: 1;
  width: 100%;
}
.homeHeaderContainer {
  min-height: 80px;
  align-items: center;
  display: flex;
  padding: 16px;
  position: fixed;
  z-index: 1;
  width: 100%;
  margin: auto;
  max-width: 1152px;
}
.shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.homeLayout {
  background: white;
  font-weight: 400 !important;
  letter-spacing: -0.3px;
}
.homeNav {
  display: inline;
  margin-left: 30px;
  font-weight: 400 !important;
}
.homeNav a {
  color: #4a4a4a;
  font-family: "KarlaReg", sans-serif;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 400 !important;
}
.homeSMMenuAction {
  margin-top: 32px;
  margin-left: 16px;
}
.homeNav a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.homeSMMenu a {
  color: #4a4a4a;
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 16px;
  letter-spacing: 0.3px;
  font-weight: 400 !important;
}
.homeSMMenu a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.homeContentContainer {
  min-height: 700px;
  padding-top: 80px;
  max-width: 1152px;
  margin: auto;
}
.logoText {
  align-self: center;
  font-family: "Karla", sans-serif;
  font-weight: 700 !important;
  color: #0a0a0a !important;
  line-height: 42px;
  margin-left: 10px;
  font-size: 28px;
}
.logoTextHomeFooter {
  align-self: center;
  font-family: "Karla", sans-serif;
  font-weight: 700 !important;
  color: #0a0a0a !important;
  line-height: 36px;
  margin-left: 8px;
  font-size: 24px;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.text {
  font-family: "Karla", sans-serif;
  font-weight: bold;
}
.homeActionContainer {
  margin-left: auto;
}
.homeFooterCopyText {
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #637282;
  font-weight: normal;
  text-align: left;
  margin-top: 16px;
}
.homeFooterCopyText a,
.homeFooterSubText a {
  color: #637282;
}
.homeFooterCopyText a:hover,
.homeFooterSubText a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.homeDemo {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 16px;
}
.homeLogin {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
}
.homeMainContentContainer {
  min-height: 580px;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homeMainContentText {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 29px;
  text-align: left;
  line-height: 42px;
  color: #1b2733;
}
.homeMainContentSubText {
  font-family: "KarlaReg", sans-serif;
  font-size: 20px;
  line-height: 29px;
  color: #637282;
  text-align: left;
  margin-top: 16px;
}
.homeEmail {
  max-width: 450px;
  align-self: flex-start;
  margin-top: 32px;
  font-family: "KarlaReg", sans-serif;
  font-size: 20px;
  line-height: 29px;
}
.homeImg {
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  -webkit-animation-name: floating;
          animation-name: floating;
  -webkit-animation-duration: 10s;
          animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
}
.homeCard {
  min-height: 180px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.1);
  margin-left: 8px;
  margin-right: 8px;
  padding: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}
.homeIconContainer {
  width: 60px;
  min-width: 60px;
  margin-right: 20px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeIconContainerFirst {
  background-color: rgba(47, 231, 177, 0.2);
  color: #2fe7b1;
}
.homeIconContainerSecond {
  background-color: rgba(111, 66, 193, 0.2);
  color: #6f42c1;
}
.homeIconContainerThird {
  background-color: rgba(123, 84, 160, 0.201);
  color: #17a2b8;
}
.homeIconContainerFour {
  background-color: rgba(126, 188, 198, 0.906);
  color: #104d57;
}
.homeIconContainerFive {
  background-color: rgba(157, 23, 184, 0.2);
  color: #046675;
}
.homeCardTitle {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  line-height: 27px;
  color: #1b2733;
}
.homeClientScreenRow {
  background-color: rgba(62, 112, 251, 0.04);
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 36px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 10px;
}
.homeMediaScreenRow {
  background-color: rgba(62, 112, 251, 0.04);
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 36px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 50px;
}
.homeDashboardScreenRow {
  background-color: rgba(62, 112, 251, 0.04);
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 36px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 50px;
}
.homeDashboardScreenTitle {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  line-height: 34px;
  color: #1b2733;
}
.homeChooseTitle {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  line-height: 34px;
  color: #1b2733;
}
.homeChooseRow {
  border-radius: 4px;
  min-height: 100px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 25px;
}
.homeDashboardScreen {
  max-width: 100%;
  height: auto;
  margin-top: 30px;
  box-shadow: 0 0 10px rgba(27, 39, 51, 0.2);
  border-radius: 5px;
}
.homeDashboardScreenSubTitle {
  font-family: "KarlaReg", sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: #637282;
  font-weight: normal;
  text-align: center;
  margin-top: -20px;
  max-width: 640px;
}
.homeCardSubTitle {
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #637282;
  font-weight: normal;
  text-align: left;
}
.smScreenMenuContainer {
  display: none;
}
.homeIcon {
  font-size: 200%;
}
.homeFeatureContainer {
  padding: 32px;
  padding-top: 10px;
  padding-bottom: 30px;
}
@-webkit-keyframes floating {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  65% {
    -webkit-transform: translate(0, -25px);
            transform: translate(0, -25px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
@keyframes floating {
  0% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
  65% {
    -webkit-transform: translate(0, -25px);
            transform: translate(0, -25px);
  }
  100% {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
  }
}
.homeFooterRow {
  min-height: 100px;
  margin: 36px;
  padding-top: 36px;
}
.homeFooterRowItem {
  margin-top: 24px;
}
.homeFooterHeader {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.homeFooterSubText {
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #637282;
  font-weight: normal;
  text-align: left;
}
.ant-carousel .slick-slide {
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  padding: 20px;
  margin-left: 50px;
  margin-right: auto;
  height: 350px;
  line-height: 250px;
  align-items: center;
  background: #470606;
  overflow: hidden;
}
.outdoor_data_main {
  text-align: center;
}
.outdoor_main_row {
  padding: 50px 4px !important;
}
.outdoor_main_label {
  font-size: 22px;
  font-weight: bold;
}
.outdoor_main_value {
  font-size: 60px;
  font-weight: bolder;
  color: #FF6600;
}
.outdoor_main_description {
  font-size: 35px;
  font-weight: bold;
  color: gray;
}
.inner_card_value {
  font-size: 40px;
}
.inner_card_label {
  font-weight: bold;
}
.ant-carousel .slick-slide h3 {
  color: rgba(0, 0, 0, 0);
}
@media screen and (min-width: 1088px) {
  .homeContent {
    max-width: 960px;
    width: 960px;
  }
  .homeHeaderContainer {
    max-width: 960px;
  }
  .homeContentContainer {
    max-width: 960px;
  }
}
.homeSMMenu {
  width: 88vw;
}
@media screen and (min-width: 1280px) {
  .homeContent {
    max-width: 1152px;
  }
  .homeHeaderContainer {
    max-width: 1152px;
  }
  .homeContentContainer {
    max-width: 1690px;
  }
}
@media screen and (max-width: 576px) {
  .homeDashboardScreenRow {
    margin-left: 16px;
    margin-right: 16px;
    padding: 10px;
    padding-top: 100px;
  }
}
@media screen and (max-width: 956px) {
  .homeNav {
    display: none;
  }
  .homeActionContainer {
    display: none;
  }
  .smScreenMenuContainer {
    display: flex;
    width: 100%;
    font-size: 20px;
    justify-content: flex-end;
  }
}

.colorInfoContainer {
  margin-top: 16px;
  font-size: 12px;
}
.colorInfoBar {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 10px;
  border-radius: 50%;
}
.boxContainer {
  text-align: center;
  max-width: 100px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colorInfoGood {
  background: #00940F;
}
.colorInfoSatisfactory {
  background: #62C94B;
}
.colorInfoModerate {
  background: #F7E22A;
}
.colorInfoPoor {
  background: #FF6600;
}
.colorInfoVeryPoor {
  background: #FF0000;
}
.colorInfoSevere {
  background: #870003;
}
.colorInfoInside {
  background: #006d86;
}
.colorInfoOutside {
  background: #00e5ff;
}

.dashboard-content-chart {
  height: 100;
}
.pmCard {
  box-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.1);
  height: 274px;
}
.noDataPmChart {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  margin: "auto";
  display: flex;
}
.aqiGraphSarva > .ant-card-body {
  padding: 0px;
}

.colorInfoContainerOps {
  margin-top: 16px;
  font-size: 6px;
}
.colorInfoBarOps {
  display: inline-block;
  height: 4px;
  width: 4px;
  margin-right: 2px;
  border-radius: 50%;
}
.boxContainerOps {
  text-align: center;
  max-width: 52px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colorInfoGood {
  background: #00940F;
}
.colorInfoSatisfactory {
  background: #62C94B;
}
.colorInfoModerate {
  background: #F7E22A;
}
.colorInfoPoor {
  background: #FF6600;
}
.colorInfoVeryPoor {
  background: #FF0000;
}
.colorInfoSevere {
  background: #870003;
}
.colorInfoInside {
  background: #006d86;
}
.colorInfoOutside {
  background: #00e5ff;
}

.brandingTextPara1 {
  font-size: x-large;
  align-items: baseline;
  margin-top: 100px;
  padding-bottom: 0px;
}
.guidelinesTextArea {
  font-size: 72px;
  font-weight: bold;
  color: #006d86;
}
.ant-card-head {
  padding: 3px 0px 3px 15px !important;
  min-height: 23px;
}
.ant-card-head-title {
  padding: 0px !important;
}
.ant-card-extra {
  padding: 3px 15px 3px 0px !important;
}
.pmCardOps > .ant-card-body {
  padding: 0px 0px 0px 14px;
}
.pmChartDisplayOps > .ant-card-body {
  padding: 0px;
  margin-left: -13%;
}
.brandingImageRowOps {
  padding: 10px 0 0 0;
}
.brand_logoOps > .ant-card-body {
  padding: 0px !important;
}
.containerOps > .ant-card-body {
  padding: 0;
  margin: 24px 24px 5px 24px;
}


.ant-layout-sider-children {
  background: #006d86;
}
.ant-card-head-title {
  color: #006d86;
  margin: 10px;
  font-weight: 700;
  font-size: 18px;
}
#components-layout-demo-responsive .logo {
  height: 32px;
  background: #006d86;
  margin: 16px;
}
.site-layout-sub-header-background {
  background: #fff;
}
.site-layout-background {
  background: #fff;
}
.ant-menu-submenu-active {
  background: #fff;
  color: #006d86;
}
.ant-menu-inline {
  background: #006d86 !important;
  border-right: none !important;
}
.ant-menu-item-selected {
  background-color: #ffffff !important;
  color: #006d86 !important;
  border-right: 0;
}
.ant-menu-item-active {
  background-color: #ffffff;
  color: #006d86;
  border-right: 0;
}
.ant-menu-submenu-selected {
  color: #fff !important;
}
.ant-menu-horizontal > .ant-menu-submenu {
  border: none !important;
}
.customerLogoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 128px;
  background-color: white;
}
.customerLogo {
  padding-left: 10px;
  padding-right: 10px;
  max-height: 100%;
  max-width: 100%;
  display: block;
}
.adminMenuHolder {
  position: absolute;
  right: 1%;
  margin-left: 20px;
  margin-top: 7px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.ant-table table {
  text-align: center !important;
}
.ant-table-thead > tr > th {
  text-align: center !important;
}
.headerLogo {
  margin-left: 20px;
  display: inline-flex;
  max-width: 300px;
  align-items: center;
}
.badge_high .ant-badge-count {
  background-color: #fff;
  color: #999;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.badge_low .ant-badge-count {
  background-color: #52c41a;
  color: #fff;
  box-shadow: 0 0 0 1px #d9d9d9 inset;
}
.claircoLogoContainer {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  height: 120px;
  background-color: white;
  padding: 3px;
}
.claircoLogo_admin {
  padding-left: 10px;
  padding-right: 10px;
  max-height: 100%;
  max-width: 100%;
  display: block;
}
.ant-menu-horizontal {
  border: none !important;
}
.important-mark::after {
  content: "*";
  color: red;
  font-size: 14px;
  padding-right: 4px;
}
.ant-menu-item-active {
  background: white !important;
  color: #00695c !important;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #0a6c5d !important;
  color: white;
  border: 1px solid #ddd;
  padding: 8px;
}
#customers td {
  border: 1px solid #ddd;
  padding: 8px;
}
#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}
#customers tr:hover {
  background-color: #ddd;
}

/* .project-details .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
} */
/* .project-details div:first-child input {
  margin-left: 15px;
} */
/* .supplier-details .flex-container .flex-child select, */
/* .supplier-details .flex-container div input,
.billing-address .flex-container div input, */
/* .shipping-address {
  margin-top: 40px;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
} */
/* .shipping-address .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
} */
/* .shipping-address div input {
  margin: 0 0 0 40px;
} */
/* .shipping-address div textarea {
  margin: 0 0 0 50px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
} */
/* .shipping-address .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
} */
/* .shipping-address .flex-container div:nth-child(1) {
  display: flex;
  align-items: center;
} */
/* 
.shipping-address .flex-container div:nth-child(1) label {
  margin-left: -98px;
}

.shipping-address .flex-container div input {
  padding-left: 10px;
} */
/* below style is for steps in multi step form */
/* below style is for hiding table column */
.container {
  width: 80%;
  margin: 0 auto;
}
.project-details {
  margin-top: 40px;
  padding: 30px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.project-details div:first-child {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.project-details div input {
  width: 260px;
  margin: 0 0 0 15px;
}
.project-details .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
}
.project-details .flex-container div {
  position: relative;
}
.project-details .flex-container div p {
  position: absolute;
  margin-top: 10px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.supplier-details {
  margin: 20px 0;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.supplier-details .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.supplier-details .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
}
.supplier-details .flex-container .flex-child {
  width: 48%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.supplier-details .flex-container .flex-child div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.supplier-details .flex-container .flex-child div label {
  width: 10%;
  justify-self: flex-start;
  font-weight: 400;
}
.supplier-details .flex-container .flex-child p {
  position: absolute;
  right: 0;
  margin-top: 40px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.supplier-details .flex-container.flex-child textarea {
  width: 80%;
}
.supplier-details .flex-container.flex-child input {
  width: 80%;
}
.supplier-details div:last-child {
  display: flex;
  align-items: flex-start;
}
.supplier-details div textarea {
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  width: 80%;
}
.billing-address {
  margin: 20px 0;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.billing-address .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.billing-address .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
}
.billing-address .flex-container .flex-child {
  width: 48%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.billing-address .flex-container .flex-child div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.billing-address .flex-container .flex-child div label {
  width: 10%;
  justify-self: flex-start;
  font-weight: 400;
}
.billing-address .flex-container .flex-child textarea {
  width: 80%;
}
.billing-address .flex-container .flex-child input {
  width: 80%;
}
.billing-address .flex-container .flex-child p {
  position: absolute;
  right: 0;
  margin-top: 40px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.billing-address div:last-child {
  display: flex;
  align-items: flex-start;
}
.billing-address div textarea {
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  width: 80%;
}
.shipping-address {
  margin: 20px 0;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.shipping-address .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.shipping-address .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
}
.shipping-address .flex-container .flex-child {
  width: 48%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.shipping-address .flex-container .flex-child div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.shipping-address .flex-container .flex-child div label {
  width: 10%;
  justify-self: flex-start;
  font-weight: 400;
}
.shipping-address .flex-container .flex-child textarea {
  width: 80%;
}
.shipping-address .flex-container .flex-child input {
  width: 80%;
}
.shipping-address .flex-container .flex-child p {
  position: absolute;
  right: 0;
  margin-top: 40px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.shipping-address .flex-container div input {
  width: 80%;
}
.shipping-address div:last-child {
  display: flex;
  align-items: flex-start;
}
.shipping-address div textarea {
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  width: 80%;
}
.step1-form {
  margin-bottom: 25px;
}
.step2-form {
  margin-bottom: 25px;
}
.message {
  width: 400px;
  border: 2px solid crimson;
  background-color: whitesmoke;
  color: black;
  margin: 10px auto;
  padding: 15px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.form-container {
  width: 580px;
  margin: 80px auto;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.inputBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.inputBox:first-child {
  margin-top: 18px;
}
label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
input {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
select {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
  padding: 6px 11px;
}
select:active {
  border: 1px solid #d9d9d9;
}
select:hover {
  border: 1px solid #d9d9d9;
}
textarea {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
option {
  padding: 6px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  background-color: whitesmoke;
  margin: "10px 0 0 0";
}
option:hover {
  background-color: #8d6a6a;
}
.order_button {
  background-color: #40a9ff;
  border: none;
  color: white;
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 0.3s;
  text-align: center;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 170px;
}
.po_button {
  background-color: #40a9ff;
  border: none;
  color: white;
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 0.3s;
  text-align: center;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 170px;
}
.select-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.select-label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
}
.sele {
  width: 410px;
  font-size: 14px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
#boq {
  width: 410px;
  font-size: 14px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
  background-color: none;
  color: black;
}
#boqupload {
  width: 477px;
}
.hidden {
  display: none;
}
.hide {
  display: none;
}

.container {
  width: 80%;
  margin: 0 auto;
}
.message {
  width: 400px;
  border: 2px solid crimson;
  background-color: whitesmoke;
  color: black;
  margin: 10px auto;
  padding: 15px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.form-container {
  width: 580px;
  margin: 5px auto;
}
.btn-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.inputBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.inputBox:first-child {
  margin-top: 12px !important;
}
label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
input {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
.order_button {
  background-color: #00ad9a;
  margin-left: 170px !important;
}
.project_details {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.project_details div:nth-child(2) {
  font-size: 18px;
}
.project_details p {
  display: inline-block;
  margin-left: 30px;
}
.project_details p b {
  color: #006d86;
  font-weight: 400;
}
.po_button {
  background-color: #00ad9a !important;
  border: none;
  color: white;
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 0.3s;
  text-align: center;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 110px;
}
.select-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.select-label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
  margin-top: 0px;
}
.sele {
  width: 410px !important;
  font-size: 14px !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  line-height: 1.575 !important;
  border-radius: 2px !important;
  transition: all 0.3s !important;
}

.loginLogoContainer {
  text-align: initial;
}
.loginLogo {
  max-height: 4.75rem;
}
.loginContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(62, 112, 251, 0.04);
}
.loginLogoText {
  align-self: center;
  font-family: "Karla", sans-serif;
  font-weight: 700 !important;
  color: #0a0a0a !important;
  line-height: 42px;
  font-size: 28px;
  margin: 16px;
  margin-bottom: 32px;
}
.loginFormButton {
  border-radius: 25px;
  width: 100px;
}
.loginBox {
  width: 70%;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.1);
}
.banner_main_image {
  width: 60%;
}
.changePassBox {
  width: auto;
  padding: 40px;
}
.changePassLogoContainer {
  text-align: center;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
.ant-menu-item-selected > .anticon {
  color: #00695c !important;
}
.ant-menu-dark .ant-menu-item-selected .anticon + span {
  color: #00695c;
}
.ant-menu:not(.ant-menu-horizontal) {
  background-color: #00695c !important;
}
#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}
.homeHeaderContainer {
  min-height: 80px;
  align-items: center;
  display: flex;
  padding: 10px;
  position: fixed;
  width: 100%;
  margin: auto;
  max-width: 1152px;
}
.logoHome {
  max-height: 5rem !important;
  float: left;
  margin-top: -16%;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.sidermenu {
  margin-top: 300px !important;
}
#components-layout-demo-custom-trigger .trigger:hover {
  color: #388dec;
}
.site-layout .site-layout-background {
  width: 100%;
  background: #fff;
  margin-top: 10px;
  padding: 10px;
}
.ant-layout-sider-children {
  box-shadow: 2px 0px 10px -3px #000;
  background-color: #00796b;
}
.ant-menu-root {
  background-color: #009688;
}
.header_menu_holder {
  position: absolute;
  right: 5%;
  display: inline-flex;
  max-width: 180px;
  align-items: center;
}
.homeContentContainer {
  max-width: 1690px;
}
.user_aqi_info_card {
  padding: 15px;
  border: none;
  box-shadow: 10px 10px 20px -25px #000000;
  height: 290px;
  border-radius: 6px;
}
.summary_aqi_holder {
  margin-left: 35px;
  width: 100%;
}
.aqi_holder_card {
  cursor: pointer;
  margin: 10px;
  padding: 8px;
  color: #fff;
  border-radius: 6px;
}
.excellent {
  background: linear-gradient(to right, #006400, #0f9b0f);
}
.good {
  background-image: linear-gradient(to right top, #00695c, #00796b, #00897b, #009688, #26a69a);
}
.moderate {
  background-image: linear-gradient(to right top, #fc4a1a, #f7b733);
}
.bad {
  background-image: linear-gradient(to right top, #ed213a, #93291e);
}
.aqi_holder_card_outside {
  cursor: pointer;
  margin: 10px;
  padding: 8px;
  color: #fff;
  border-radius: 6px;
  background-image: linear-gradient(to right top, #db2e2e, #e86111, #e98d00, #e1b800, #cee011);
}
.aqi_holder_card_name {
  font-size: 18px;
  font-weight: 500;
}
.aqi_holer_card_value {
  font-size: 26px;
  font-weight: 700;
}
.aqi_holder_card_unit {
  font-size: 17px;
  font-weight: 400;
}
.user_list_holder {
  box-shadow: 10px 10px 20px -25px #000000;
  margin-left: 45px;
  width: 79.5%;
}
.user_gauge_card_carousel {
  margin-top: 30px !important;
  padding: 15px;
  border: none;
  box-shadow: 10px 10px 20px -25px #000000;
  height: 290px;
  border-radius: 6px;
  text-align: -webkit-center;
}
.aqi_info_card_title {
  font-size: 50px;
  font-weight: 500;
}
.aqi_info_card_subtitle {
  font-size: 18px;
  font-weight: 200;
}
.aqi_info_card_image {
  position: fixed;
  width: 300px;
  height: 220px;
  float: right;
}
.temp_data {
  font-size: 13px;
}
.circle-background,
.circle-progress {
  fill: none;
}
.circle-background {
  stroke: #828080;
}
.circle-dashes {
  stroke: #fff;
  fill: none;
}
.gauge_card_icon_holder {
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 7px;
  background-color: #00695c;
  border-radius: 5px;
  box-shadow: 10px 10px 20px -25px #000000;
  text-align: center;
}
.gauge_card_desc {
  position: absolute;
  top: 0;
  left: 25px;
  text-align: left;
  padding: 10px;
  font-size: 15px;
}
.aqi_indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: inline-flex;
  margin-left: 10px;
  margin-right: 10px;
}
.green {
  background-color: #26a69a;
}
.red {
  background-color: #ef5350;
}
.aqi_card_header_title {
  float: left;
  background: rgba(255, 255, 255, 0.8);
  border-radius: 4%;
  padding: 0px 10px 0px 10px;
}
.indoor {
  color: #006d86;
}
.outdoor {
  color: #ef5350;
}
.center {
  margin: auto;
  position: relative;
  top: 35%;
}
.gauge_outer_ring {
  position: absolute;
}
.gauge_inside_progress {
  position: absolute;
  width: 200px;
}
.CircularProgressbar .CircularProgressbar-text {
  font-size: 25px !important;
  fill: rgba(0, 0, 0, 0.65) !important;
  color: #26a69a !important;
}
.excellent_aqi > .CircularProgressbar-path {
  stroke: #00940f !important;
}
.good_aqi > .CircularProgressbar-path {
  stroke: #009966 !important;
}
.moderate_aqi > .CircularProgressbar-path {
  stroke: #ffde33 !important;
}
.bad_aqi > .CircularProgressbar-path {
  stroke: #ff9933 !important;
}
.sever_aqi > .CircularProgressbar-path {
  stroke: #cc0033 !important;
}
.hazard_aqi > .CircularProgressbar-path {
  stroke: #7e0023 !important;
}
.gauge_card_icon_label {
  position: absolute;
  top: 0;
  right: 25px;
  text-align: right;
  padding: 10px;
  font-size: 20px;
}
.gauge_card_icon_info {
  position: absolute;
  bottom: 0;
  left: 25px;
  text-align: left;
  padding: 10px;
  font-size: 13px;
}
.gauge_info_icon {
  cursor: pointer;
  color: red;
}
.gauge_card_bg {
  width: 100%;
  opacity: 0.1;
  height: 95%;
}
.image_container {
  width: 98.2%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}
.chartjs-render-monitor {
  height: 209px !important;
  width: 460px !important;
}
.aqi_card_header {
  margin-right: 10px;
  cursor: pointer;
}
.ant-card-body {
  padding: 9px !important;
}
.ant-card-head {
  border-bottom: none !important;
  color: #fff;
  text-align: right;
}
.gauge_container {
  display: none;
}
.active {
  display: block !important;
}
.ant-menu-inline {
  background-color: #23538a !important;
}
#tsparticles {
  position: absolute;
  margin-top: -46px;
  margin-left: -18px;
  height: 100%;
}
.download_button {
  width: 100%;
  border: none;
  border-radius: 0;
  background-color: #23538a;
  color: rgba(255, 255, 255, 0.65);
  padding-left: 20px;
}
.add_user {
  background-color: #388dec;
  color: #fff;
  font-weight: 500;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}
#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}
#customers tr:hover {
  background-color: #ddd;
}
#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #4caf50;
  color: white;
}
.customer_name_container {
  background-color: #fff;
  padding: 10px;
  text-align: center;
  margin-top: 30px;
  border-radius: 20px;
  font-size: 14px;
  color: #00695c !important;
  margin-left: 15px;
  width: 170px;
}
.col_header_custom {
  background: #dedede;
  color: #00796b;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bolder;
  padding: 10px;
  margin: 5px 0 5px 0;
}
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.tooltip .tooltiptext {
  visibility: visible;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.ant-menu-item-active {
  background: white !important;
  color: #00695c !important;
}

.report-menu {
  background-color: #fafafa !important;
  color: #000000 !important;
}
.report-menu .ant-menu-item-selected > .anticon {
  color: #000000 !important;
}
.report-menu .ant-menu-light .ant-menu-item-selected {
  color: #000000 !important;
  background: #4db6ac !important;
  width: 100%;
}
.report-menu .ant-menu:not(.ant-menu-horizontal) {
  background-color: #fafafa !important;
}
.report-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.report-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #4db6ac !important;
  color: #fafafa !important;
}
.report-menu .ant-menu-vertical .ant-menu-item::after,
.report-menu .ant-menu-vertical-left .ant-menu-item::after,
.report-menu .ant-menu-vertical-right .ant-menu-item::after,
.report-menu .ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}
.dates-menu {
  background-color: #fafafa !important;
  color: #000000 !important;
  font-family: "'Montserrat', sans-serif" !important;
}
.dates-menu .ant-menu-light .ant-menu-item-selected {
  color: #000000 !important;
  background: #4db6ac !important;
}
.dates-menu .ant-menu:not(.ant-menu-vertical) {
  background-color: #fafafa !important;
  color: black !important;
  font-family: 'Montserrat', sans-serif !important;
}
.dates-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.dates-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #4db6ac !important;
  color: #fafafa !important;
}
.scrollMenu {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.scrollMenu::-webkit-scrollbar {
  width: 5px;
}
.scrollMenu::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scrollMenu::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.scrollMenu::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}

