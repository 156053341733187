.report-menu {
  background-color: #fafafa !important;
  color: #000000 !important;
}
.report-menu .ant-menu-item-selected > .anticon {
  color: #000000 !important;
}
.report-menu .ant-menu-light .ant-menu-item-selected {
  color: #000000 !important;
  background: #4db6ac !important;
  width: 100%;
}
.report-menu .ant-menu:not(.ant-menu-horizontal) {
  background-color: #fafafa !important;
}
.report-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.report-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #4db6ac !important;
  color: #fafafa !important;
}
.report-menu .ant-menu-vertical .ant-menu-item::after,
.report-menu .ant-menu-vertical-left .ant-menu-item::after,
.report-menu .ant-menu-vertical-right .ant-menu-item::after,
.report-menu .ant-menu-inline .ant-menu-item::after {
  border-right: 0px;
}
.dates-menu {
  background-color: #fafafa !important;
  color: #000000 !important;
  font-family: "'Montserrat', sans-serif" !important;
}
.dates-menu .ant-menu-light .ant-menu-item-selected {
  color: #000000 !important;
  background: #4db6ac !important;
}
.dates-menu .ant-menu:not(.ant-menu-vertical) {
  background-color: #fafafa !important;
  color: black !important;
  font-family: 'Montserrat', sans-serif !important;
}
.dates-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
.dates-menu .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
  background: #4db6ac !important;
  color: #fafafa !important;
}
.scrollMenu {
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.scrollMenu::-webkit-scrollbar {
  width: 5px;
}
.scrollMenu::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scrollMenu::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}
.scrollMenu::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}
