.dashboard-content-chart {
  height: 100;
}
.pmCard {
  box-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.1);
  height: 274px;
}
.noDataPmChart {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  margin: "auto";
  display: flex;
}
.rtAqOutside {
  display: flex;
  align-items: center;
  justify-content: center;
}
.rtAqOutsideContainer {
  display: flex;
  margin: 10px;
  border-radius: 20px;
  width: 80px;
  height: 40px;
  align-items: center;
  color: #FFF;
  font-weight: bolder;
  font-size: large;
  justify-content: center;
}
.rtAqOutsideContainer2 {
  background-color: aqua;
  display: flex;
  margin: 10px;
  border-radius: 20px;
  padding: 10px;
  align-items: center;
  color: #000;
  font-weight: bolder;
  font-size: large;
  justify-content: center;
}
.separator {
  margin: 0 !important;
}
.aqiGraphSarva > .ant-card-body {
  padding: 0px;
}
.propertiesLoaderContainer {
  height: 100vh;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
