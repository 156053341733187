.dashboard-content-chart {
  height: 100;
}
.pmCard {
  box-shadow: 0 0.5px 2px rgba(0, 0, 0, 0.1);
  height: 274px;
}
.noDataPmChart {
  min-height: 300px;
  justify-content: center;
  align-items: center;
  margin: "auto";
  display: flex;
}
.aqiGraphSarva > .ant-card-body {
  padding: 0px;
}
