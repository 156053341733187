.logoHome {
  max-height: 5rem;
  float: left;
}
.logoHomeFooter {
  max-height: 6rem;
  float: left;
}
.homeProfileNameText {
  padding: 5px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  justify-content: center;
  font-size: 25px;
  color: #0a0a0a;
}
.homeProfileContentText {
  padding: 2px;
  padding-top: 0px;
  display: inline-block;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  justify-content: center;
  font-size: 20px;
  color: #0a0a0a;
  max-height: 40px;
  min-height: 40px;
}
.logoProfile {
  max-height: 12rem;
  border-radius: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.logoLinkedin {
  max-height: 2.5rem;
  border-radius: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.homeProfileTextContainer {
  min-height: 580px;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homeProfileLogo {
  font-size: 40px;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  color: #0E76A8;
}
.homeLogo {
  font-size: 40px;
  align-items: center;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}
.homeHeader {
  background: white;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px;
  position: fixed;
  z-index: 1;
  width: 100%;
}
.homeHeaderContainer {
  min-height: 80px;
  align-items: center;
  display: flex;
  padding: 16px;
  position: fixed;
  z-index: 1;
  width: 100%;
  margin: auto;
  max-width: 1152px;
}
.shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.homeLayout {
  background: white;
  font-weight: 400 !important;
  letter-spacing: -0.3px;
}
.homeNav {
  display: inline;
  margin-left: 30px;
  font-weight: 400 !important;
}
.homeNav a {
  color: #4a4a4a;
  font-family: "KarlaReg", sans-serif;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.3px;
  font-weight: 400 !important;
}
.homeSMMenuAction {
  margin-top: 32px;
  margin-left: 16px;
}
.homeNav a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.homeSMMenu a {
  color: #4a4a4a;
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 24px;
  margin: 16px;
  letter-spacing: 0.3px;
  font-weight: 400 !important;
}
.homeSMMenu a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.homeContentContainer {
  min-height: 700px;
  padding-top: 80px;
  max-width: 1152px;
  margin: auto;
}
.logoText {
  align-self: center;
  font-family: "Karla", sans-serif;
  font-weight: 700 !important;
  color: #0a0a0a !important;
  line-height: 42px;
  margin-left: 10px;
  font-size: 28px;
}
.logoTextHomeFooter {
  align-self: center;
  font-family: "Karla", sans-serif;
  font-weight: 700 !important;
  color: #0a0a0a !important;
  line-height: 36px;
  margin-left: 8px;
  font-size: 24px;
}
.logoContainer {
  display: flex;
  align-items: center;
}
.text {
  font-family: "Karla", sans-serif;
  font-weight: bold;
}
.homeActionContainer {
  margin-left: auto;
}
.homeFooterCopyText {
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #637282;
  font-weight: normal;
  text-align: left;
  margin-top: 16px;
}
.homeFooterCopyText a,
.homeFooterSubText a {
  color: #637282;
}
.homeFooterCopyText a:hover,
.homeFooterSubText a:hover {
  color: #000000;
  border-bottom: 1px solid #000000;
}
.homeDemo {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
  margin-left: 16px;
}
.homeLogin {
  font-family: "Karla", sans-serif;
  font-weight: bold;
  border-radius: 25px;
}
.homeMainContentContainer {
  min-height: 580px;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.homeMainContentText {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 29px;
  text-align: left;
  line-height: 42px;
  color: #1b2733;
}
.homeMainContentSubText {
  font-family: "KarlaReg", sans-serif;
  font-size: 20px;
  line-height: 29px;
  color: #637282;
  text-align: left;
  margin-top: 16px;
}
.homeEmail {
  max-width: 450px;
  align-self: flex-start;
  margin-top: 32px;
  font-family: "KarlaReg", sans-serif;
  font-size: 20px;
  line-height: 29px;
}
.homeImg {
  height: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  animation-name: floating;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}
.homeCard {
  min-height: 180px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.1);
  margin-left: 8px;
  margin-right: 8px;
  padding: 20px;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
}
.homeIconContainer {
  width: 60px;
  min-width: 60px;
  margin-right: 20px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homeIconContainerFirst {
  background-color: rgba(47, 231, 177, 0.2);
  color: #2fe7b1;
}
.homeIconContainerSecond {
  background-color: rgba(111, 66, 193, 0.2);
  color: #6f42c1;
}
.homeIconContainerThird {
  background-color: rgba(123, 84, 160, 0.201);
  color: #17a2b8;
}
.homeIconContainerFour {
  background-color: rgba(126, 188, 198, 0.906);
  color: #104d57;
}
.homeIconContainerFive {
  background-color: rgba(157, 23, 184, 0.2);
  color: #046675;
}
.homeCardTitle {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  line-height: 27px;
  color: #1b2733;
}
.homeClientScreenRow {
  background-color: rgba(62, 112, 251, 0.04);
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 36px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 10px;
}
.homeMediaScreenRow {
  background-color: rgba(62, 112, 251, 0.04);
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 36px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 50px;
}
.homeDashboardScreenRow {
  background-color: rgba(62, 112, 251, 0.04);
  border-radius: 4px;
  min-height: 100px;
  margin-bottom: 36px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 50px;
}
.homeDashboardScreenTitle {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 24px;
  text-align: center;
  line-height: 34px;
  color: #1b2733;
}
.homeChooseTitle {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 30px;
  text-align: center;
  line-height: 34px;
  color: #1b2733;
}
.homeChooseRow {
  border-radius: 4px;
  min-height: 100px;
  margin-top: 20px;
  margin-bottom: 0px;
  margin-left: 36px;
  margin-right: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 100px;
  padding-bottom: 25px;
}
.homeDashboardScreen {
  max-width: 100%;
  height: auto;
  margin-top: 30px;
  box-shadow: 0 0 10px rgba(27, 39, 51, 0.2);
  border-radius: 5px;
}
.homeDashboardScreenSubTitle {
  font-family: "KarlaReg", sans-serif;
  font-size: 20px;
  line-height: 25px;
  color: #637282;
  font-weight: normal;
  text-align: center;
  margin-top: -20px;
  max-width: 640px;
}
.homeCardSubTitle {
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #637282;
  font-weight: normal;
  text-align: left;
}
.smScreenMenuContainer {
  display: none;
}
.homeIcon {
  font-size: 200%;
}
.homeFeatureContainer {
  padding: 32px;
  padding-top: 10px;
  padding-bottom: 30px;
}
@keyframes floating {
  0% {
    transform: translate(0, 0);
  }
  65% {
    transform: translate(0, -25px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.homeFooterRow {
  min-height: 100px;
  margin: 36px;
  padding-top: 36px;
}
.homeFooterRowItem {
  margin-top: 24px;
}
.homeFooterHeader {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}
.homeFooterSubText {
  font-family: "KarlaReg", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #637282;
  font-weight: normal;
  text-align: left;
}
.ant-carousel .slick-slide {
  text-align: center;
  padding-left: 50px;
  padding-right: 50px;
  padding: 20px;
  margin-left: 50px;
  margin-right: auto;
  height: 350px;
  line-height: 250px;
  align-items: center;
  background: #470606;
  overflow: hidden;
}
.outdoor_data_main {
  text-align: center;
}
.outdoor_main_row {
  padding: 50px 4px !important;
}
.outdoor_main_label {
  font-size: 22px;
  font-weight: bold;
}
.outdoor_main_value {
  font-size: 60px;
  font-weight: bolder;
  color: #FF6600;
}
.outdoor_main_description {
  font-size: 35px;
  font-weight: bold;
  color: gray;
}
.inner_card_value {
  font-size: 40px;
}
.inner_card_label {
  font-weight: bold;
}
.ant-carousel .slick-slide h3 {
  color: rgba(0, 0, 0, 0);
}
@media screen and (min-width: 1088px) {
  .homeContent {
    max-width: 960px;
    width: 960px;
  }
  .homeHeaderContainer {
    max-width: 960px;
  }
  .homeContentContainer {
    max-width: 960px;
  }
}
.homeSMMenu {
  width: 88vw;
}
@media screen and (min-width: 1280px) {
  .homeContent {
    max-width: 1152px;
  }
  .homeHeaderContainer {
    max-width: 1152px;
  }
  .homeContentContainer {
    max-width: 1690px;
  }
}
@media screen and (max-width: 576px) {
  .homeDashboardScreenRow {
    margin-left: 16px;
    margin-right: 16px;
    padding: 10px;
    padding-top: 100px;
  }
}
@media screen and (max-width: 956px) {
  .homeNav {
    display: none;
  }
  .homeActionContainer {
    display: none;
  }
  .smScreenMenuContainer {
    display: flex;
    width: 100%;
    font-size: 20px;
    justify-content: flex-end;
  }
}
