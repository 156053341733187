.loginLogoContainer {
  text-align: initial;
}
.loginLogo {
  max-height: 4.75rem;
}
.loginContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(62, 112, 251, 0.04);
}
.loginLogoText {
  align-self: center;
  font-family: "Karla", sans-serif;
  font-weight: 700 !important;
  color: #0a0a0a !important;
  line-height: 42px;
  font-size: 28px;
  margin: 16px;
  margin-bottom: 32px;
}
.loginFormButton {
  border-radius: 25px;
  width: 100px;
}
.loginBox {
  width: 70%;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(99, 114, 130, 0.1);
}
.banner_main_image {
  width: 60%;
}
.changePassBox {
  width: auto;
  padding: 40px;
}
.changePassLogoContainer {
  text-align: center;
}
