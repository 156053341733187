.brandingTextPara1 {
  font-size: x-large;
  align-items: baseline;
  margin-top: 100px;
  padding-bottom: 0px;
}
.guidelinesTextArea {
  font-size: 72px;
  font-weight: bold;
  color: #006d86;
}
.ant-card-head {
  padding: 3px 0px 3px 15px !important;
  min-height: 23px;
}
.ant-card-head-title {
  padding: 0px !important;
}
.ant-card-extra {
  padding: 3px 15px 3px 0px !important;
}
.pmCardOps > .ant-card-body {
  padding: 0px 0px 0px 14px;
}
.pmChartDisplayOps > .ant-card-body {
  padding: 0px;
  margin-left: -13%;
}
.brandingImageRowOps {
  padding: 10px 0 0 0;
}
.brand_logoOps > .ant-card-body {
  padding: 0px !important;
}
.containerOps > .ant-card-body {
  padding: 0;
  margin: 24px 24px 5px 24px;
}
