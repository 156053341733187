/* .project-details .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
} */
/* .project-details div:first-child input {
  margin-left: 15px;
} */
/* .supplier-details .flex-container .flex-child select, */
/* .supplier-details .flex-container div input,
.billing-address .flex-container div input, */
/* .shipping-address {
  margin-top: 40px;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
} */
/* .shipping-address .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
} */
/* .shipping-address div input {
  margin: 0 0 0 40px;
} */
/* .shipping-address div textarea {
  margin: 0 0 0 50px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
} */
/* .shipping-address .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
} */
/* .shipping-address .flex-container div:nth-child(1) {
  display: flex;
  align-items: center;
} */
/* 
.shipping-address .flex-container div:nth-child(1) label {
  margin-left: -98px;
}

.shipping-address .flex-container div input {
  padding-left: 10px;
} */
/* below style is for steps in multi step form */
/* below style is for hiding table column */
.container {
  width: 80%;
  margin: 0 auto;
}
.project-details {
  margin-top: 40px;
  padding: 30px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}
.project-details div:first-child {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.project-details div input {
  width: 260px;
  margin: 0 0 0 15px;
}
.project-details .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 18px 0;
}
.project-details .flex-container div {
  position: relative;
}
.project-details .flex-container div p {
  position: absolute;
  margin-top: 10px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.supplier-details {
  margin: 20px 0;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.supplier-details .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.supplier-details .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
}
.supplier-details .flex-container .flex-child {
  width: 48%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.supplier-details .flex-container .flex-child div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.supplier-details .flex-container .flex-child div label {
  width: 10%;
  justify-self: flex-start;
  font-weight: 400;
}
.supplier-details .flex-container .flex-child p {
  position: absolute;
  right: 0;
  margin-top: 40px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.supplier-details .flex-container.flex-child textarea {
  width: 80%;
}
.supplier-details .flex-container.flex-child input {
  width: 80%;
}
.supplier-details div:last-child {
  display: flex;
  align-items: flex-start;
}
.supplier-details div textarea {
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  width: 80%;
}
.billing-address {
  margin: 20px 0;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.billing-address .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.billing-address .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
}
.billing-address .flex-container .flex-child {
  width: 48%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.billing-address .flex-container .flex-child div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.billing-address .flex-container .flex-child div label {
  width: 10%;
  justify-self: flex-start;
  font-weight: 400;
}
.billing-address .flex-container .flex-child textarea {
  width: 80%;
}
.billing-address .flex-container .flex-child input {
  width: 80%;
}
.billing-address .flex-container .flex-child p {
  position: absolute;
  right: 0;
  margin-top: 40px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.billing-address div:last-child {
  display: flex;
  align-items: flex-start;
}
.billing-address div textarea {
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  width: 80%;
}
.shipping-address {
  margin: 20px 0;
  padding: 10px 15px;
  background-color: white;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.shipping-address .title {
  text-transform: uppercase;
  font-size: 16px;
  color: #00ad9a;
  font-weight: 700;
}
.shipping-address .flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  text-transform: capitalize;
  margin-top: 20px;
  width: 100%;
  padding: 20px;
}
.shipping-address .flex-container .flex-child {
  width: 48%;
  display: flex;
  flex-direction: column;
  position: relative;
}
.shipping-address .flex-container .flex-child div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
}
.shipping-address .flex-container .flex-child div label {
  width: 10%;
  justify-self: flex-start;
  font-weight: 400;
}
.shipping-address .flex-container .flex-child textarea {
  width: 80%;
}
.shipping-address .flex-container .flex-child input {
  width: 80%;
}
.shipping-address .flex-container .flex-child p {
  position: absolute;
  right: 0;
  margin-top: 40px;
  font-size: 12px;
  color: gray;
  font-weight: 400;
  right: 0px;
  text-transform: capitalize;
}
.shipping-address .flex-container div input {
  width: 80%;
}
.shipping-address div:last-child {
  display: flex;
  align-items: flex-start;
}
.shipping-address div textarea {
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  border-radius: 2px;
  width: 80%;
}
.step1-form {
  margin-bottom: 25px;
}
.step2-form {
  margin-bottom: 25px;
}
.message {
  width: 400px;
  border: 2px solid crimson;
  background-color: whitesmoke;
  color: black;
  margin: 10px auto;
  padding: 15px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.form-container {
  width: 580px;
  margin: 80px auto;
}
.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.inputBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
.inputBox:first-child {
  margin-top: 18px;
}
label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
input {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
select {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
  padding: 6px 11px;
}
select:active {
  border: 1px solid #d9d9d9;
}
select:hover {
  border: 1px solid #d9d9d9;
}
textarea {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
option {
  padding: 6px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  background-color: whitesmoke;
  margin: "10px 0 0 0";
}
option:hover {
  background-color: #8d6a6a;
}
.order_button {
  background-color: #40a9ff;
  border: none;
  color: white;
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 0.3s;
  text-align: center;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 170px;
}
.po_button {
  background-color: #40a9ff;
  border: none;
  color: white;
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 0.3s;
  text-align: center;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 170px;
}
.select-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.select-label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
}
.sele {
  width: 410px;
  font-size: 14px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
#boq {
  width: 410px;
  font-size: 14px;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
  background-color: none;
  color: black;
}
#boqupload {
  width: 477px;
}
.hidden {
  display: none;
}
.hide {
  display: none;
}
