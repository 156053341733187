.container {
  width: 80%;
  margin: 0 auto;
}
.message {
  width: 400px;
  border: 2px solid crimson;
  background-color: whitesmoke;
  color: black;
  margin: 10px auto;
  padding: 15px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.form-container {
  width: 580px;
  margin: 5px auto;
}
.btn-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.inputBox {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}
.inputBox:first-child {
  margin-top: 12px !important;
}
label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
}
input {
  width: 410px;
  border: 1px solid #d9d9d9;
  padding: 4px 11px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1.575;
  border-radius: 2px;
  transition: all 0.3s;
}
.order_button {
  background-color: #00ad9a;
  margin-left: 170px !important;
}
.project_details {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.project_details div:nth-child(2) {
  font-size: 18px;
}
.project_details p {
  display: inline-block;
  margin-left: 30px;
}
.project_details p b {
  color: #006d86;
  font-weight: 400;
}
.po_button {
  background-color: #00ad9a !important;
  border: none;
  color: white;
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 400;
  transition: all 0.3s;
  text-align: center;
  height: 32px;
  font-size: 14px;
  cursor: pointer;
  margin-left: 110px;
}
.select-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.select-label {
  font-weight: 400;
  width: 150px;
  direction: rtl;
  margin-top: 0px;
}
.sele {
  width: 410px !important;
  font-size: 14px !important;
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.85) !important;
  line-height: 1.575 !important;
  border-radius: 2px !important;
  transition: all 0.3s !important;
}
